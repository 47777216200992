<template>
	<div class="home">
		<section id="section1" class="center">
			<template v-if="$root.rwd < 2">
				<lottie class="lottie-left" :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
				<lottie class="lottie-right" :options="{ path: '/lottie/Miscanthus02/data.json', autoplay: true, loop: true }" />
			</template>
			<div style="width:100%; max-width:1200px; z-index:2;">
				<template v-if="$root.rwd < 3">
					<carousel-special :options="{interval: 5000, loop: true, duration: 1000}" control>
						<template v-for="(item, i) in banners1">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel-special>
				</template>
				<template v-else>
					<carousel-special :options="{interval: 5000, loop: true, duration: 1000}" control>
						<template v-for="(item, i) in banners1_mobile">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel-special>
				</template>
				<br>
				<br>
				<div class="row" align-x="center" align-y="center" gutter="10">
					<img src="@/assets/quote-left.png" style="width:1.3em;">
					<div class="col" align-x="center" gutter="10">
						<h1>探索金枝演社</h1>
						<p>融合傳統與現代的台戲美學</p>
					</div>
					<img src="@/assets/quote-right.png" style="width:1.3em;">
				</div>
				<br>
				<br>
			</div>
		</section>
		<section id="section2" class="center">
			<div class="row-grid" align-x="center" gutter-x="20" gutter-y="20" style="max-width:1200px;">
				<div class="rwd-m-3 rwd-s-6">
					<router-link to="/activity">
						<img src="@/assets/home/2-1.png" width="100%">
						<div class="arrow" />
						<p>最新活動</p>
					</router-link>
				</div>
				<div class="rwd-m-3 rwd-s-6">
					<router-link to="/news">
						<img src="@/assets/home/2-2.png" width="100%">
						<div class="arrow" />
						<p>焦點消息</p>
					</router-link>
				</div>
				<div class="rwd-m-3 rwd-s-6">
					<router-link to="/portfolio">
						<img src="@/assets/home/2-3.png" width="100%">
						<div class="arrow" />
						<p>系列作品</p>
					</router-link>
				</div>
				<div class="rwd-m-3 rwd-s-6">
					<router-link to="/perform">
						<img src="@/assets/home/2-4.png" width="100%">
						<div class="arrow" />
						<p>金枝走演</p>
					</router-link>
				</div>
				<div class="rwd-m-6">
					<router-link to="/about">
						<img src="@/assets/home/2-5.png" width="100%">
						<div class="arrow" />
						<p class="bottom">關於金枝演社<br><small>從土地長出來的文化最感人</small></p>
					</router-link>
				</div>
				<div class="rwd-m-3 rwd-xs-6">
					<router-link to="/about/founder">
						<img src="@/assets/home/2-6.png" width="100%">
						<div class="arrow" />
						<p class="bottom"><small>創辦人暨藝術總監</small><br>王榮裕</p>
					</router-link>
				</div>
				<div class="rwd-m-3 rwd-xs-6">
					<router-link to="/about/mother">
						<img src="@/assets/home/2-7.png" width="100%">
						<div class="arrow" />
						<p class="bottom"><small>金枝的阿母</small><br>謝月霞</p>
					</router-link>
				</div>
			</div>
		</section>
		<section id="section3" class="center">
			<template v-if="$root.rwd < 2">
				<lottie class="lottie-left" :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
				<lottie class="lottie-right" :options="{ path: '/lottie/Miscanthus02/data.json', autoplay: true, loop: true }" />
			</template>
			<div style="width:100%; max-width:1200px; z-index:2;">
				<template v-if="$root.rwd < 3">
					<carousel :options="{interval: 5000, loop: true, duration: 1000}" indicator control>
						<template v-for="(item, i) in banners2">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel>
				</template>
				<template v-else>
					<carousel :options="{interval: 5000, loop: true, duration: 1000}" indicator control>
						<template v-for="(item, i) in banners2_mobile">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel>
				</template>
				<div style="padding-top:10%; padding-bottom:10%;">
					<br>
					<br ref="timeline" id="timeline">
					<div class="row" align-x="center" align-y="center" gutter="10">
						<img src="@/assets/quote-left.png" style="width:1.3em;">
						<h1>金枝年表</h1>
						<img src="@/assets/quote-right.png" style="width:1.3em;">
					</div>
					<br>
					<div class="row-grid" align-x="center" gutter-x="10" gutter-y="10">
						<template v-for="(tab) in Object.keys(timeline)">
							<div :key="tab">
								<el-button type="theme" :class="{active: currentTab == tab}" round @click="changeTab(tab)">{{tab}}</el-button>
							</div>
						</template>
					</div>
					<br>
					<div ref="scale-anime" class="row-grid" align-x="around">
						<template v-for="(item, key) in timeline[currentTab]">
							<div class="timeline-item col rwd-m-4 rwd-s-6" :key="key" gutter="10">
								<b>{{item.year}}</b>
								<div class="col" gutter="5">
									<p v-if="item.title">{{item.title}}</p>
									<pre>{{item.chronicle}}</pre>
								</div>
							</div>
						</template>
					</div>
				</div>
				<br>
				<template v-if="$root.rwd < 3">
					<carousel :options="{interval: 5000, loop: true, duration: 1000}" indicator control>
						<template v-for="(item, i) in banners3">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel>
				</template>
				<template v-else>
					<carousel :options="{interval: 5000, loop: true, duration: 1000}" indicator control>
						<template v-for="(item, i) in banners3_mobile">
							<img :src="item.url" width="100%" :key="i">
						</template>
					</carousel>
				</template>
				<br>
				<br>
				<div style="padding:10%;">
					<div class="row" align-x="center" align-y="center" gutter="10">
						<img src="@/assets/quote-left.png" style="width:1.3em;">
						<h1>訂閱電子報</h1>
						<img src="@/assets/quote-right.png" style="width:1.3em;">
					</div>
					<br>
					<p style="text-align:center;">馬上訂閱金枝演社，獲取最新的演出資訊。</p>
					<el-form :model="ruleForm" ref="ruleForm" @submit.native.prevent style="max-width:500px; margin:auto;">
						<el-form-item prop="email" :rules="[{required:true, message:'請填正確信箱', trigger:'change'}, {type:'email', message:'請填正確信箱', trigger:'blur'}]">
							<el-input v-model="ruleForm.email" placeholder="電子信箱">
								<el-button slot="append" type="success" @click="subscribe()">立即訂閱</el-button>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</section>
		<section id="section4">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.2879692608867!2d121.44339456113765!3d25.141780863521006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a57fa68889ad%3A0xebdbeae30087b28!2z6YeR5p6d5ryU56S-!5e0!3m2!1szh-TW!2stw!4v1581416729666!5m2!1szh-TW!2stw" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen />
		</section>
	</div>
</template>

<script>
import { scaleAnime } from "@/libs/anime";
import { mapState, mapActions } from "vuex";
export default {
	components: {
		CarouselSpecial: () => import("@/components/carousel-special"),
		Carousel: () => import("@/components/carousel"),
		Lottie: () => import("@/components/lottie")
	},
	data() {
		return {
			banners1: new Array,
			banners1_mobile: new Array,
			banners2: new Array,
			banners2_mobile: new Array,
			banners3: new Array,
			banners3_mobile: new Array,
			timeline: new Object,
			currentTab: "",
			ruleForm: new Object,
		}
	},
	computed: {
		...mapState("home", ["_home"]),
	},
	methods: {
		...mapActions("home", ["_getData"]),
		...mapActions("subscribe", ["_subscribe"]),
		subscribe() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.$message.success(await this._subscribe(this.ruleForm));
					this.ruleForm = new Object;
					this.$refs["ruleForm"].resetFields();
				}
			})
		},
		autoScrollToTimeline() {
			this.$nextTick(() => {
				const el = document.getElementById(location.hash.replace("#", ""));
				if (el) el.scrollIntoView({ behavior: "smooth" });
			})
		},
		changeTab(val) {
			this.currentTab = val;
			if (this.$refs["timeline"]) this.$refs["timeline"].scrollIntoView({ behavior: "smooth" });
			scaleAnime(this.$refs["scale-anime"].children);
		}
	},
	async created() {
		await this._getData();
		this._.assign(this.$data, this._home);
		this.currentTab = Object.keys(this.timeline)[0];
		this.autoScrollToTimeline()
	}
}
</script>

<style lang="scss" scoped>
#section1 {
	background-image: url("../../assets/background/brush1.png"),
		url("../../assets/background/dot1.png");
	background-position: center bottom, center 80%;
	position: relative;
	@media screen and (min-width: 769px) {
		padding: 3%;
	}

	.lottie-left,
	.lottie-right {
		position: absolute;
		bottom: 0;
		width: 25%;
		max-width: 300px;
		transform: translateY(20%);
	}

	.lottie-left {
		left: 20px;
	}
	.lottie-right {
		right: 20px;
	}
}

#section2 {
	background: #325327;
	padding: 5%;

	.row-grid {
		@media screen and (min-width: 769px) {
			margin-top: -10%;
		}
	}

	[class*="rwd-"] {
		a {
			display: block;
			transition: 0.3s;
			position: relative;

			> p {
				position: absolute;
				top: 5%;
				left: 5%;
				color: #fff;
				font-size: 1.2em;
				text-shadow: black 0.1em 0.1em 0.2em;

				&.bottom {
					top: unset;
					bottom: 5%;
				}
			}
		}

		.arrow {
			position: absolute;
			right: 10px;
			bottom: 10px;
			width: 40px;
			border-radius: 100%;
			padding: 0;
			transition: 0.5s;
			border: none;
			cursor: pointer;
			outline: none;
			border: 1px solid rgba($color: #fff, $alpha: 0.5);

			&::before {
				content: "";
				display: block;
				padding-top: 100%;
			}

			&::after {
				content: "";
				transform: translate(-75%, -50%) rotate(45deg);
				display: block;
				width: 25%;
				height: 25%;
				border-style: solid;
				border-width: 1px 1px 0 0;
				border-color: #fff;
				position: absolute;
				top: 50%;
				left: 50%;
			}
		}

		&:hover {
			a {
				box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
				transform: scale(1.05);

				.arrow {
					background: $theme;
					border-color: transparent;
				}
			}
		}
	}
}

#section3 {
	position: relative;
	background-image: url("../../assets/background/brush2.png"),
		url("../../assets/background/brush3.png"),
		url("../../assets/background/dot2.png"),
		url("../../assets/background/dot3.png");
	background-position: center, center bottom, center, center 95%;
	background-size: 100%, 100%, 90%, 90%;
	@media screen and (min-width: 769px) {
		padding: 3%;
	}

	.timeline-item {
		padding: 4%;
		text-align: center;
		b {
			font-size: 1.3em;
			color: $theme;
		}
		p {
			font-size: 1.2em;
		}
		pre {
			text-align: center;
			font-size: smaller;
			line-height: 2em;
		}
	}

	.lottie-left,
	.lottie-right {
		position: absolute;
		width: 25%;
		max-width: 300px;
	}

	.lottie-left {
		bottom: 0;
		left: 20px;
		transform: translateY(20%);
	}
	.lottie-right {
		bottom: 10%;
		right: 20px;
	}
}
section {
	background-size: 100%;
	background-repeat: no-repeat;

	// 蓋住背景稻草
	z-index: 2;
	position: relative;
}
</style>

<style lang="scss">
.home {
	#section3 {
		.el-input-group__append,
		.el-input-group__prepend {
			background: #325327 !important;
			color: #fff;
		}
	}
}
</style>